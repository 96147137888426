<template>
  <v-container style="text-align: -webkit-center" id="register">
    <v-card class="card-resposive pa-0 ma-0 pb-4 pt-4">
      <v-img
        height="35"
        width="150"
        contain
        src="../../../../assets/logo-imprimax.svg"
      ></v-img>
      <v-card-text class="pa-0 text-center">
        <div style="display: flex; justify-content: flex-start" class="py-5">
          <span class="cadastre-se">Cadastre-se</span>
        </div>

        <v-form
          style="
            text-align: -webkit-center;
            padding: 0 30px;
            text-align-last: left;
          "
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="py-2">
            <span class="label">Nome</span>
            <v-text-field
              dense
              tabindex="1"
              outlined
              hide-details
              :rules="[rules.required]"
              class="primary-input"
              v-model="currentUser.name"
              @keydown.enter="register()"
              placeholder="Primeiro nome..."
              required
            />
          </div>

          <div class="py-2">
            <span class="label">Sobrenome</span>
            <v-text-field
              dense
              tabindex="1"
              outlined
              hide-details
              :rules="[rules.required]"
              class="primary-input"
              v-model="lastName"
              @keydown.enter="register()"
              placeholder="Sobrenome..."
              required
            />
          </div>

          <div class="py-2">
            <span class="label">E-mail</span>
            <v-text-field
              tabindex="2"
              outlined
              dense
              hide-details
              class="primary-input"
              v-model="currentUser.email"
              :rules="emailRules"
              @keydown.enter="register()"
              placeholder="Digite seu e-mail para login..."
              required
            />
          </div>

          <div class="py-2">
            <span class="label">CPF/CNPJ</span>
            <v-text-field
              tabindex="2"
              outlined
              dense
              hide-details
              class="primary-input"
              v-model="currentUser.cnpj"
              :rules="[rules.validateCPF, rules.validateCNPJ]"
              v-mask="
                this.cnpjLength() <= 14
                  ? '###.###.###-##'
                  : '##.###.###/####-##'
              "
              @keydown.enter="register()"
              placeholder="Digite seu cpf ou cnpj"
              required
            />
          </div>

          <div class="py-2">
            <span class="label">Celular</span>
            <v-text-field
              tabindex="2"
              outlined
              dense
              hide-details
              class="primary-input"
              v-model="currentUser.phoneNumber"
              placeholder="(11) 98541-74111"
              v-mask="['(##) #####-####']"
              :rules="[rules.required, rules.min]"
              @keydown.enter="register()"
              required
            />
          </div>

          <div class="py-2">
            <span class="label">Senha</span>
            <v-text-field
              outlined
              dense
              tabindex="3"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              hide-details
              class="primary-input"
              v-model="currentUser.password"
              :rules="passwordRules"
              @click:append="show1 = !show1"
              placeholder="Digite sua senha..."
              required
            />
          </div>

          <div class="py-2">
            <span class="label">Confirmação de senha</span>
            <v-text-field
              outlined
              dense
              tabindex="4"
              :type="show2 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              hide-details
              class="primary-input"
              v-model="currentUser.password2"
              @click:append="show2 = !show2"
              @keydown.enter="register()"
              :rules="[
                this.currentUser.password === this.currentUser.password2 ||
                  'As senhas devem ser iguais.',
              ]"
              placeholder="Confirme sua senha..."
              required
            />
          </div>
          <v-row class="pa-0 pt-5" style="width: 100%">
            <v-btn
              block
              @click="register()"
              :loading="loading"
              class="primary-button"
            >
              Cadastrar
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "../../../../services/ApiService";
import Vue from "vue";
import Utils from "../../../../Utils/ValidateCPFCNPJ";

export default {
  name: "Register",

  components: {},

  data: () => ({
    isEditing: false,
    show1: false,
    show2: false,
    lastName: "",
    currentUser: {
      phoneNumber: "",
    },
    loading: false,
    error: false,
    valid: true,
    email: "",
    rules: {
      validateCPF: (value) =>
        value
          ? Utils.CPF(value) || value.toString().replace(/\D/g, "").length > 11
          : "Cpf Inválido",
      validateCNPJ: (value) =>
        value
          ? Utils.CNPJ(value) ||
            value.toString().replace(/\D/g, "").length <= 11
          : "Cnpf Inválido",
      min: (value) => value.length >= 14 || "Telefone invalido.",
      required: (value) => !!value || "Campo requerido.",
    },
    emailRules: [
      (v) =>
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          v
        ) || "E-mail inválido.",
    ],

    password: "",
    passwordRules: [(v) => !!v || "Senha requerida"],
    apiService: new ApiService(),
  }),

  async created() {},
  methods: {
    cnpjLength() {
      if (!this.currentUser.cnpj) {
        return 0;
      }
      return this.currentUser.cnpj.length;
    },

    async register() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const cliente = {
          Name: `${this.currentUser.name} ${this.lastName}`,
          Email: this.currentUser.email,
          Password: this.currentUser.password,
          Cnpj: this.currentUser.cnpj,
          PhoneNumber: this.currentUser.phoneNumber,
        };

        let url = "client/add";
        if (this.isEditing) {
          url = "client/edit";
        }
        const client = new ApiService();

        await client
          .post(url, cliente)
          .then((resp) => {
            if (this.isEditing) {
              var email = {
                Name: cliente.Name,
                To: cliente.Email,
                Subject: "IMPRIMAX - Alteração cadastral",
                Message: `Olá, seu cadastro foi alterado recentemente.
                .Para acessar a alteração de dados, vá até a pagina de configuracões, que se encontra no seu menu de usuario no canto superior direito!`,
                Type: "cliente",
              };
            } else {
              var email = {
                Url: process.env.VUE_APP_BASE_URL_FRONT,
                Name: cliente.Name,
                To: cliente.Email,
                Subject: "Senha provisória IMPRIMAX Autoadesivos",
                Message: `${cliente.Password}`,
                Type: "cliente",
              };
            }

            this.sendEmailClient(email);

            this.$router.push("/login");
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Erro ao cadastrar!",
              message: err.body.message,
              trace: null,
            });
          });
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Formulário não validado",
          message: "Preencha todos os campos corretamente",
          trace: null,
        });
      }

      this.loading = false;
    },

    async sendEmailClient(email) {
      this.loading = true;
      await this.apiService
        .post("email/sendClient", email)
        .then((resp) => {
          if (this.isEditing) {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "info",
              title: "Cadastro atalizado com sucesso!",
              message: "Seus dados foram atualizados",
              trace: null,
            });
          } else {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "success",
              title: "Cadastro efetuado com sucesso!",
              message: "Bem vindo à IMPRIMAX!",
              trace: null,
            });
          }
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro",
            message: "E-mail inválido",
            trace: null,
          });

          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
#register {
  font-family: Poppins, sans-serif !important;
}
.label {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #000000;
  padding-left: 10px;
}
.cadastre-se {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #2d3038;
  padding-left: 30px;
}
.card-resposive {
  width: 40%;
  height: auto;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
}

@media (max-width: 800px) {
  .card-resposive {
    width: 95%;
    height: auto !important;
    margin: auto;
  }
}
</style>
